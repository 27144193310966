import React, { useEffect, useRef, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Row, Col, Nav, Form } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from "../components/Avatar";
import useWebSocket from 'react-use-websocket';
import Config from '../Config.json';

import img16 from "../assets/img/img16.jpg";
import gearsTurningGif from "../assets/img/gears_turning.gif";
import robotProfilePic from "../assets/img/robot_profile_1.jpg";
import userProfilePic from "../assets/img/man_profile_1.jpg"

const WS_URL = 'wss://6aiolymuhf.execute-api.us-east-2.amazonaws.com/prod';

export default function Chat() {
    const [chatActive, setChatActive] = useState(0);
    const inputRef = useRef();
    const messagesEndRef = useRef(null);
    const [msgShow, setMsgShow] = useState(false);
    const navigate = useNavigate();
    const [prompt, setPrompt] = useState('')
    const [serverMessage, setServerMessage] = useState({'context': '', 'answer': ''});
    const [showProgress, setShowProgress] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [userId] = useState(localStorage.getItem('user_id'));
    const [chatGroup, setChatGroup] = useState([
        {
            "selected": false,
            "avatar": {
            "status": "online",
            "img": robotProfilePic
            },
            "sender": "U.S. Army Five Docs",
            "time": "1d",
            "text": "Regulations for the U.S. Army"
        },
        // {
        //     "unread": false,
        //     "avatar": {
        //     "status": "online",
        //     "img": robotProfilePic
        //     },
        //     "sender": "AR 5-22",
        //     "time": "2d",
        //     "text": "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        // },
        // {
        //     "avatar": {
        //     "status": "online",
        //     "img": robotProfilePic
        //     },
        //     "sender": "TM 8-6515-006-24&P",
        //     "time": "3d",
        //     "text": "There are many variations of paserror sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
        // },
    ]);
    const [messageGroup, setMessageGroup] = useState([
        {
            "date": "January 15, 2024",
            "items": [
                {
                    "avatar": {
                        "status": "online",
                        "img": img16
                    },
                    "messages": [
                        {
                            "text": "Excepteur sint occaecat cupidatat non proident",
                            "time": "8:45pm"
                        }, {
                            "text": "Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.",
                            "time": "9:15pm"
                        }
                    ]
                }, {
                    "reverse": true,
                    "messages": [
                        {
                            "text": "Neque porro quisquam est, qui dolorem ipsum",
                            "time": "9:20pm"
                        }, {
                            "text": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accus",
                            "time": "9:21pm"
                        }
                    ]
                }
            ]
        }, {
            "date": "January 16, 2024",
            "items": [
                {
                    "avatar": {
                        "status": "online",
                        "img": img16
                    },
                    "messages": [
                        {
                            "text": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                            "time": "10:30am"
                        }
                    ]
                }, {
                    "reverse": true,
                    "messages": [
                        {
                            "text": "Accusantium doloremque laudantium",
                            "time": "10:40am"
                        }, {
                            "text": "Accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo...",
                            "time": "10:41am"
                        }
                    ]
                }, {
                    "avatar": {
                        "status": "online",
                        "img": img16
                    },
                    "messages": [
                        {
                            "text": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit...",
                            "time": "10:45am"
                        }, {
                            "text": "Nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?",
                            "time": "10:47am"
                        }
                    ]
                }
            ]
        }
    ]);
    const [dialogue, setDialogue] = useState({
        'dialogue_id': `${localStorage.getItem('user_id')}-${localStorage.getItem('optimus')}-prompt`,
        'messages': []
    });

    const checkLogin = () => {
        if (localStorage.getItem('optimus') === null || String(localStorage.getItem('optimus')).length !== parseInt(process.env.REACT_APP_TOKEN_LENGTH)) {
            navigate('/login');
        }
    }

    const { getWebSocket } = useWebSocket(WS_URL + '?userId=' + userId, {
        onOpen: (event) => {
            setSubmitDisabled(false);
            console.log('Connected');
        },
        onMessage: (event) => {
            const message = JSON.parse(event.data).message;
            const finalAugmentedAnswer = JSON.parse(message);
            setServerMessage(finalAugmentedAnswer);

            if (finalAugmentedAnswer.context) {
                const newMessage = 'Document Citations:\n\n' + finalAugmentedAnswer.context;
                const updatedMessages = dialogue.messages.length > 0 
                    ? [...dialogue.messages.slice(0, -1), newMessage] 
                    : [newMessage];

                setDialogue({
                    ...dialogue,
                    messages: updatedMessages
                });
            }
            if (finalAugmentedAnswer.answer) {
                setDialogue({
                    ...dialogue,
                    messages: [...dialogue.messages, 'Final Answer:\n\n' + finalAugmentedAnswer.answer]
                });
                setShowProgress(false);
                setSubmitDisabled(false);
            }
        },
        shouldReconnect: (closeEvent) => true
    });

    const aiMessage = (text) => {
        const firstTwoWords = text.split(' ').slice(0, 2).join(' ');
        return firstTwoWords.includes('In progress') || firstTwoWords.includes('Document Citations') || firstTwoWords.includes('Final Answer');
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPrompt(value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    const handleSubmit = (event) => {
        const updatedDialogue = {
            ...dialogue, // Spread the existing fields of the dialogue object
            messages: [...dialogue.messages, prompt] // Create a new array with the old dialogue plus the new message
        };

        const updatedDialogueInProgress = {
            ...updatedDialogue,
            messages: [...updatedDialogue.messages, 'In progress']
        };

        setDialogue(updatedDialogueInProgress);
        setPrompt('');
        setServerMessage('New prompt submitted.');
        setShowProgress(true);
        setSubmitDisabled(true);
        
        fetch('https://vrg1g9e1va.execute-api.us-east-2.amazonaws.com/augmentedanswerrequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.augmentedanswerrequest,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedDialogue)
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                setShowProgress(false);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            to=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="header-link"
            >
            {children}
        </Link>
    ));
    
    const navToggle = (e) => {
        // Toggle chat option in each item
        e.target.closest(".row").classList.toggle("nav-show");
    };

    var y = window.scrollY;

    useEffect(() => {
        checkLogin();
        document.body.classList.add('page-app');
        inputRef.current.focus();
        
        // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

        // Delay scrolling to ensure messages have been rendered
        // const timer = setTimeout(() => {
        //     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        // }, 100); // Adjust the timeout as needed

        var element = document.querySelector('.ref');
        window.scroll({
            top: element.scrollHeight,
            left: 0,
        });

        return () => {
            // clearTimeout(timer);
            document.body.classList.remove('page-app')
        }
    }, [dialogue.messages]);

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className={"chat-panel" + (msgShow? " msg-show" : "")}>
                    <div className="chat-sidebar">
                        <div className="sidebar-header">
                            <h6 className="sidebar-title me-auto">AI Conversations</h6>

                            <Dropdown align="end">
                                <Dropdown.Toggle as={CustomToggle}>
                                    <i className="ri-more-2-fill"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href=""><i className="ri-user-add-line"></i> Invite People</Dropdown.Item>
                                    <Dropdown.Item href=""><i className="ri-question-answer-line"></i> Create Channel</Dropdown.Item>
                                    <Dropdown.Item href=""><i className="ri-server-line"></i> Server Settings</Dropdown.Item>
                                    <Dropdown.Item href=""><i className="ri-bell-line"></i> Notification Settings</Dropdown.Item>
                                    <Dropdown.Item href=""><i className="ri-lock-2-line"></i> Privacy Settings</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Link className="header-link ms-1" title="New message">
                                <i className="ri-chat-new-line"></i>
                            </Link>
                        </div>

                        <PerfectScrollbar className="sidebar-body">
                            {/* <label className="sidebar-label mb-3">Recently Contacted</label>
                            <div className="chat-contacts mb-4">
                                <Row className="g-2 row-cols-auto">
                                <Col><Avatar img={img10} status="offline" /></Col>
                                <Col><Avatar img={img11} status="online" /></Col>
                                <Col><Avatar img={img12} status="online" /></Col>
                                <Col><Avatar img={img14} status="online" /></Col>
                                <Col><Avatar img={img15} status="offline" /></Col>
                                <Col><Avatar img={img6} status="online" /></Col>
                                </Row>
                            </div> */}

                            {/* <label className="sidebar-label mb-2">Channels</label>

                            <div className="chat-group mb-4">
                                {[
                                {
                                    "name": "General",
                                    "desc": "Includes <strong>You</strong>, <strong>Dyanne</strong> and <strong>5 others</strong>"
                                }, {
                                    "name": "Technology",
                                    "desc": "Includes <strong>You</strong>, <strong>Nathan</strong> and <strong>1 other</strong>"
                                }, {
                                    "name": "Production",
                                    "desc": "Includes all <strong>Engineering</strong> teams"
                                }
                                ].map((channel, index) => (
                                <div className="chat-item" key={index}>
                                    <div className="avatar"><span className="avatar-initial">#</span></div>
                                    <div className="chat-item-body">
                                    <h6>{channel.name}</h6>
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(channel.desc) }} />
                                    </div>
                                </div>
                                ))}
                            </div> */}

                            <label className="sidebar-label mb-2">Messages</label>

                            <div className="chat-group">
                                {chatGroup.map((chats, index) => (
                                    <div 
                                        key={index} 
                                        className={"chat-item" + (chats.unread ? " unread" : "") + ((chatActive === index)? " selected" : "")} 
                                        onClick={(e) => { 
                                            setChatActive(index); 
                                            setMsgShow(true);
                                        }}>
                                        <Avatar status={chats.avatar.status} img={chats.avatar.img} />
                                        <div className="chat-item-body">
                                            <div className="d-flex align-items-center mb-1">
                                                <h6 className="mb-0">{chats.sender}</h6>
                                                <small className="ms-auto">{chats.time}</small>
                                            </div>
                                            <span>{chats.text}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </PerfectScrollbar>
                    </div>
                    
                    <div className="chat-body">
                        <div className="chat-body-header">
                            <div className="chat-item">
                                <Avatar img={robotProfilePic} status="online" />
                                <div className="chat-item-body">
                                    <h6 className="mb-1">{chatGroup[chatActive].sender}</h6>
                                    <span>Active now</span>
                                </div>
                            </div>
                            <Nav className="nav-icon ms-auto">
                                <Nav.Link href="" title="Invite People"><i className="ri-user-add-line"></i></Nav.Link>
                                <Nav.Link href="" title="Member List"><i className="ri-group-line"></i></Nav.Link>
                                <Nav.Link href="" title="Call"><i className="ri-phone-line"></i></Nav.Link>
                                <Nav.Link href="" title="Video Call"><i className="ri-vidicon-line"></i></Nav.Link>
                                <Nav.Link href="" title="More Info"><i className="ri-information-line"></i></Nav.Link>
                                <Nav.Link href="" className="d-md-none" onClick={() => setMsgShow(false)}><i className="ri-close-fill"></i></Nav.Link>
                            </Nav>
                        </div>

                        {/* <PerfectScrollbar className="chat-body-content">
                            {messageGroup.map((msgroup, index) => (
                                <React.Fragment key={index}>
                                    <div className="divider"><span>{msgroup.date}</span></div>
                                    {msgroup.items.map((item, ind) => (
                                        <div key={ind} className={"msg-item" + (item.reverse ? " reverse" : "")}>
                                            {item.avatar && <Avatar status={item.avatar.status} img={item.avatar.img} />}
                                            <div className="msg-body">
                                                {item.messages.map((message, i) => (
                                                <Row key={i} className="gx-3 row-cols-auto">
                                                    <Col>
                                                        <div className="msg-bubble" onMouseOver={navToggle} onMouseLeave={navToggle}>
                                                            {message.text}
                                                            <span>{message.time}</span>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <Nav className="nav-icon">
                                                            <Nav.Link href=""><i className="ri-reply-line"></i></Nav.Link>
                                                            <Nav.Link href=""><i className="ri-more-fill"></i></Nav.Link>
                                                        </Nav>
                                                    </Col>
                                                </Row>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </React.Fragment>
                            ))}
                        </PerfectScrollbar> */}

                        <PerfectScrollbar className="chat-body-content ref">
                            {dialogue.messages.map((msg, index) => (
                                <React.Fragment key={index}>
                                    <div className="msg-body">
                                        <Row key={index} className="gx-3 row-cols-auto">
                                            <Col>
                                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                                    <div style={{ flexShrink: 0, marginRight: "10px" }}>
                                                        <Avatar img={aiMessage(msg) ? robotProfilePic : userProfilePic} status="online" />
                                                    </div>
                                                    <div className="msg-bubble" onMouseOver={navToggle} onMouseLeave={navToggle}>
                                                        {
                                                            msg === 'In progress' ?
                                                            
                                                                <><img src={gearsTurningGif} width="35px" /> Examining documents to locate the right answer.</>
                                                            
                                                            : msg.split('\n').map((line, index, arr) => (
                                                                <>
                                                                <React.Fragment key={index}>
                                                                    {
                                                                        line.includes("Document Citations:") || line.includes("Final Answer:") ?
                                                                            
                                                                            <>
                                                                            <b>{line}</b>
                                                                            {/* <div ref={messagesEndRef} /> */}
                                                                            </> 
                                                                        
                                                                            : line
                                                                    }
                                                                    {index < arr.length - 1 && <br />}
                                                                </React.Fragment>

                                                                
                                                                </>
                                                            ))
                                                        }

                                                        {/* {showProgress ? <><br /><br />I will now look into the vector database to get the best answer for you</> : ""} */}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Nav className="nav-icon">
                                                    <Nav.Link href=""><i className="ri-reply-line"></i></Nav.Link>
                                                    <Nav.Link href=""><i className="ri-more-fill"></i></Nav.Link>
                                                </Nav>
                                            </Col>
                                        </Row><br />
                                    </div>
                                </React.Fragment>
                            ))}
                            
                        </PerfectScrollbar>

                        <div className="chat-body-footer">
                            <Nav className="nav-icon">
                                <Nav.Link href=""><i className="ri-add-line"></i></Nav.Link>
                                <Nav.Link href=""><i className="ri-image-line"></i></Nav.Link>
                                <Nav.Link href=""><i className="ri-gift-line"></i></Nav.Link>
                                <Nav.Link href=""><i className="ri-emotion-happy-line"></i></Nav.Link>
                            </Nav>
                            <div className="msg-box">
                                <Form.Control
                                    type="text"
                                    id="prompt"
                                    name="prompt"
                                    ref={inputRef}
                                    value={prompt}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Write your question..."
                                    autoComplete="off" />
                                <Link href="" className="msg-send" onClick={handleSubmit}><i className="ri-send-plane-2-line"></i></Link>
                            </div>
                            <Nav className="nav-icon">
                                <Nav.Link href=""><i className="ri-thumb-up-line"></i></Nav.Link>
                            </Nav>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </React.Fragment>
    );
}