import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import bg1 from "../assets/img/bg1.jpg";
import Config from '../Config.json';

export default function Login() {
    const inputRef = useRef();
    const navigate = useNavigate();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const [login, setLogin] = useState({
        'email': '',
        'password': ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setLogin({
            ...login,
            [name]: value
        });

        setErrorMessage('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitDisabled(true);

        fetch('https://quefg0iqb8.execute-api.us-east-2.amazonaws.com/systemauthenticate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.systemauthenticate,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'user_id': login.email,
                'password': login.password
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response['token'].length === 34) {
                    localStorage.setItem('user_id', response.user_id);
                    localStorage.setItem('optimus', response.token);
                    localStorage.setItem('first_name', response.first_name);
                    localStorage.setItem('last_name', response.last_name);
                    navigate('/mastercloserai');
                    // if (!localStorage.getItem('sidebar-skin')) {
                    //     localStorage.setItem('sidebar-skin', 'prime');
                    //     navigate('/callgraderai');
                    //     window.location.reload();
                    // } else {
                    //     navigate('/callgraderai');
                    // }
                } else {
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('optimus');
                    localStorage.removeItem('first_name');
                    localStorage.removeItem('last_name');
                    setErrorMessage('Invalid login');
                    setSubmitDisabled(false);
                }
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const sidebarSkin = (e) => {
        e.preventDefault();
        e.target.classList.add("active");

        let node = e.target.parentNode.firstChild;
        while (node) {
            if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
            node.classList.remove("active");
            node = node.nextElementSibling || node.nextSibling;
        }

        let skin = e.target.textContent.toLowerCase();
        let HTMLTag = document.querySelector("html");

        HTMLTag.removeAttribute("data-sidebar");

        if (skin !== "default") {
            HTMLTag.setAttribute("data-sidebar", skin);
            localStorage.setItem("sidebar-skin", skin);
        } else {
            localStorage.removeItem("sidebar-skin", skin);
        }
    };

    useEffect(() => {
        if (!localStorage.getItem('reloaded')) {
            localStorage.setItem('reloaded', 'true');
            window.location.reload();
        }

        if (!localStorage.getItem('sidebar-skin')) {
            localStorage.setItem('sidebar-skin', 'prime');
            sidebarSkin();
        }

        inputRef.current.focus();
    }, []);

    return (
        <div className="page-sign d-block py-0">
            <Row className="g-0">
                <Col md="7" lg="5" xl="4" className="col-wrapper">
                    <Card className="card-sign">
                        <Card.Header>
                            <Link to="/" className="header-logo mb-5">Gale Force AI</Link>
                            <Card.Title>Sign In</Card.Title>

                            {errorMessage ?
                                <div className="text-danger"><b>{errorMessage}</b></div>
                                
                                : <Card.Text>Please signin to continue.</Card.Text>
                            }
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" id="email" name="email" value={login.email} onChange={handleChange} placeholder="Enter your email address" ref={inputRef} />
                                </div>
                                <div className="mb-4">
                                    <Form.Label className="d-flex justify-content-between">
                                    Password <Link to="/pages/forgot">Forgot password?</Link>
                                    </Form.Label>
                                    <Form.Control type="password" id="password" name="password" value={login.password} onChange={handleChange} placeholder="Enter your password" />
                                </div>
                                <Button type="submit" className="btn-sign" disabled={submitDisabled}>Sign In</Button>

                                <div className="divider"><span>or sign in with</span></div>

                                <Row className="gx-2">
                                    <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
                                    <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
                                </Row>
                            </Form>
                        </Card.Body>
                        <Card.Footer>
                            Don't have an account? <Link to="/pages/signup2">Create an Account</Link>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col className="d-none d-lg-block">
                    <img src={bg1} className="auth-img" alt="" />
                </Col>
            </Row>
        </div>
    )
}