import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Config from '../Config.json';
import Table from '../data/Table';
import '../assets/css/Custom.css'

const WS_URL = 'wss://6aiolymuhf.execute-api.us-east-2.amazonaws.com/prod';

export default function MasterCloserAI() {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const navigate = useNavigate();
    const [serverMessage, setServerMessage] = useState('');
    const [showProgress, setShowProgress] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [userId] = useState(localStorage.getItem('user_id'));
    const [contacts, setContacts] = useState([{
        'id': '',
        'Full_Name': '',
        'Account_Name': {'name': ''},
        'Title': '',
        'Email': '',
        'Phone': '',
        'Notes': []
    }]);
    const updateContacts = (newContacts) => {
        // Function to update contacts state
        // in parent, to be passed to child
        setContacts(newContacts);
    };
    const [contact, setContact] = useState({
        'contact_id': '',
        'contact_name': '',
        'company_name': '',
        'email': '',
        'phone': '',
        'owner': ''
    });

    const checkLogin = () => {
        if (localStorage.getItem('optimus') === null || String(localStorage.getItem('optimus')).length !== parseInt(process.env.REACT_APP_TOKEN_LENGTH)) {
            navigate('/login');
        }
    }

    const { getWebSocket } = useWebSocket(WS_URL + '?userId=' + userId, {
        onOpen: () => {
            setSubmitDisabled(false);
            console.log('Connected');
        },
        onMessage: (event) => {
            const message = JSON.parse(event.data).message;
            setServerMessage(message);

            if (message.length > 200) {
                setServerMessage(message);
                setSubmitDisabled(false);
            }
        },
        shouldReconnect: (closeEvent) => true
    });

    const fetchContactList = (event) => {
        fetch('https://byisudhfwe.execute-api.us-east-2.amazonaws.com/masterclosercontactlist', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.masterclosercontactlist,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'master_closer_id': getMasterCloserId()})
        })
            .then((response) => response.json())
            .then((response) => {
                setContacts(response.data);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        
    };

    const handleSubmit = (event) => {
        event.preventDefault();  // Prevent the default form submit action.
        
        setSubmitDisabled(true);
        setServerMessage('Assessment request submitted.');
        
        fetch('https://e80cevazaf.execute-api.us-east-2.amazonaws.com/callgraderscorecardrequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.callgraderscorecardrequest,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contact)
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                setShowProgress(false);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const getMasterCloserId = () => {
        return `${localStorage.getItem('user_id')}-${localStorage.getItem('optimus')}-text`;
    }

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    };

    useEffect(() => {
        checkLogin();
        fetchContactList();
        switchSkin(skin);
    }, [skin]);

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Generative AI</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Master Closer AI</li>
                        </ol>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h4 className="main-title mb-0"><b>Master Closer AI</b></h4>
                        </div>
                    </div>
                </div>

                <Row className="g-3">
                    <Col xl="12">
                        <Card className="card-one">
                            <Card.Body className="p-4">
                                <form onSubmit={handleSubmit}>
                                    <Row className="g-4">
                                        <Col md="6">
                                            <div className="d-flex">
                                                <div className="mb-3 flex-grow-1">
                                                    {
                                                        contacts.length > 1 ?
                                                        <Table contacts={contacts}
                                                               updateContacts={updateContacts}
                                                               serverMessage={serverMessage} />
                                                        : <b>Loading CRM data...</b>
                                                    }
                                                </div>
                                                <div>
                                                    {/* {contacts[1].Full_Name} */}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="d-flex" style={{ height: "100%", marginTop: "0px", padding: "0rem" }}>
                                                <div className="mb-3 flex-grow-1">
                                                    
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>

                                <Row className="g-4" style={{ marginTop: "1px" }}>
                                    <Col md="6">
                                        <div className="d-flex">
                                            <div className="mb-3 flex-grow-1">
                                                
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="d-flex">
                                            <div className="mb-3 flex-grow-1">
                                                {serverMessage ? "" : "" }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Footer />
            </div>
        </React.Fragment>
    );
}
