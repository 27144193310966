import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import useWebSocket from 'react-use-websocket';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Config from '../Config.json';
import '../assets/css/Custom.css'

const WS_URL = 'wss://6aiolymuhf.execute-api.us-east-2.amazonaws.com/prod';

export default function CallGraderAI() {
    const { dialogueType, setDialogueType } = useState('text');  // useParams();
    const [buttonColor, setButtonColor] = useState('');
    const currentTime = new Date().toLocaleTimeString();
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [file, setFile] = useState(null);
    const inputRef = useRef();  // Reference to store the text input DOM element
    const navigate = useNavigate();
    const [serverMessage, setServerMessage] = useState('');
    const [showProgress, setShowProgress] = useState(true);
    const [skin, setSkin] = useState(currentSkin);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [userId] = useState(localStorage.getItem('user_id'));
    const [dialogue, setDialogue] = useState({
        'dialogue_id': '',
        'dialogue': "Speaker A: Hello. John speaking.\nSpeaker B: Hi John, I'm Andy from Andy AI Consulting. I'm calling to discuss how our AI consulting services can benefit your business. Do you have a moment?\nSpeaker A: Sure, I'm actually quite interested in integrating AI into our operations. We're looking at customer service enhancements.\nSpeaker B: Great to hear! Our services focus on leveraging AI for advanced data analytics and predictive modeling. It can really transform your data handling capabilities.\nSpeaker A: That sounds useful, but my primary interest is in AI for customer interactions, like chatbots. Do you specialize in that area?\nSpeaker B: Absolutely, data analytics with AI is a game-changer. It helps in understanding market trends and customer behavior more effectively.\nSpeaker A: Yes, but about the AI for customer service – that's where we need the most help. Can you tell me more about that?\nSpeaker B: Of course, with our AI solutions, you can process and analyze customer data much faster, leading to more informed business decisions.\nSpeaker A: I see. Well, it seems we might be talking about different things. I'm really swamped right now, so can we talk another time about specific AI solutions for customer service?\nSpeaker B: Sure, John, we can definitely revisit this. I'll send over some general information about our services and we can schedule another call to dive into details.\nSpeaker A: That sounds good, Andy. I'll look out for your email. Thanks.\nSpeaker B: Thank you, John. Looking forward to our next discussion. Have a great day!\nSpeaker A: You too, goodbye.\nSpeaker B: Goodbye!",
        'rubric': 'Loading saved data...',
        'rubric_type': ''
    });
    const [scorecard, setScorecard] = useState({
        'representative_name': '',
        'customer_name': '',
        'dialogue_type': '',
        'assessments': [],
        'assessments_summary': '',
        'dialogue_quotes': [],
        'action_item': '',
        'enhanced_dialogue': [],
    });

    const checkLogin = () => {
        if (localStorage.getItem('optimus') === null || String(localStorage.getItem('optimus')).length !== parseInt(process.env.REACT_APP_TOKEN_LENGTH)) {
            navigate('/login');
        }
    }

    const { getWebSocket } = useWebSocket(WS_URL + '?userId=' + userId, {
        onOpen: () => {
            setSubmitDisabled(false);
            console.log('Connected!');
        },
        onMessage: (event) => {
            const message = JSON.parse(event.data).message;
            setServerMessage(message);

            if (message.length > 200) {
                const assessmentObject = JSON.parse(message);
                setServerMessage(message);
                setScorecard(assessmentObject);
                setSubmitDisabled(false);
            }
        },
        shouldReconnect: (closeEvent) => true
    });

    const fetchRubric = (event) => {
        fetch('https://rn9yd7e2sd.execute-api.us-east-2.amazonaws.com/callgraderrubricrequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.callgraderrubricrequest,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'rubric_id': 'perf-sales-01', 'company_id': 'perf0602'})
        })
            .then((response) => response.json())
            .then((response) => {
                const updatedDialogue = {
                    ...dialogue,
                    rubric: response.rubric,
                    rubric_type: response.rubric_type
                };
                setDialogue(updatedDialogue);
                setShowProgress(false);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setDialogue({
            ...dialogue,
            [name]: value
        });
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        console.log(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();  // Prevent the default form submit action.
        
        // if (!file) {
        //     alert('Please select a file first!');
        //     return;
        // }

        // const formData = new FormData();
        // formData.append('soundFile', file);
        
        setSubmitDisabled(true);
        setServerMessage('Assessment request submitted.');
        setScorecard({'representative_name': ''});

        const updatedDialogue = {
            ...dialogue,
            dialogue_id: getDialogueId()
        };
        setDialogue(updatedDialogue);
        console.log('updatedDialogue: ' + updatedDialogue);
        
        fetch('https://e80cevazaf.execute-api.us-east-2.amazonaws.com/callgraderscorecardrequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.callgraderscorecardrequest,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedDialogue)
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                setShowProgress(false);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const getDialogueId = () => {
        return `${localStorage.getItem('user_id')}-${localStorage.getItem('optimus')}-text`;
    }

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    };

    useEffect(() => {
        checkLogin();
        fetchRubric();
        if (dialogueType === 'text') inputRef.current.focus();
        console.log('dialogueType: ' + dialogueType);
        switchSkin(skin);
    }, [skin]);

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Generative AI</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Call Grader AI</li>
                        </ol>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h4 className="main-title mb-0"><b>Call Grader AI</b></h4>
                        </div>
                    </div>
                </div>

                <Row className="g-3">
                    <Col xl="12">
                        <Card className="card-one">
                            <Card.Body className="p-4">
                                <form onSubmit={handleSubmit}>
                                    <Row className="g-4">
                                        <Col md="6">
                                            <div className="d-flex">
                                                <div className="mb-3 flex-grow-1">
                                                    {
                                                        dialogueType === "text" || true ? 

                                                            <>
                                                            <Form.Label htmlFor="dialogue"><b>Dialogue:</b></Form.Label>
                                                            <Form.Control 
                                                                as="textarea"
                                                                id="dialogue"
                                                                name="dialogue"
                                                                ref={inputRef}
                                                                value={dialogue.dialogue}
                                                                onChange={handleChange}
                                                                placeholder=""
                                                                rows="5"
                                                                style={{
                                                                    height: "19.8rem",
                                                                    borderColor: "#c3d0e3",
                                                                    padding: "0.375rem 0.75rem",
                                                                    fontSize: "0.91rem",
                                                                    lineHeight: "1.5",
                                                                    color: "#495057",
                                                                    backgroundColor: "#b8e3e6",
                                                                    borderRadius: "0.75rem",
                                                                    boxShadow: "inset 0 0.25rem 0.5rem rgba(0, 0, 0, 0.075)"
                                                                }}></Form.Control>
                                                            </>
                                                            
                                                            : 
                                                            
                                                                <>
                                                                <Form.Label htmlFor="upload"><b>Upload:</b></Form.Label>
                                                                <input type="file" onChange={handleFileChange} accept="audio/*" />
                                                                </>
                                                    }
                                                    
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                <button type="submit" className="btn btn-primary" style={{ backgroundColor: buttonColor, width: "200px" }} disabled={submitDisabled}>Submit</button>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="d-flex" style={{ height: "100%", marginTop: "0px", padding: "0rem" }}>
                                                <div className="mb-3 flex-grow-1">
                                                    {serverMessage ?
                                                        <>
                                                        <Form.Label><b>Assessment:</b></Form.Label><br />
                                                        <div style={{ 
                                                            marginTop: "1px",
                                                            padding: "10px",
                                                            backgroundImage: 'url("https://galeforceai-public.s3.us-east-2.amazonaws.com/sectors/Banking_and_Financial_Services/transparent-jar-of-coins.jpg")',
                                                            height: '315px',
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                            borderRadius: "0.75rem" }}>
                                                            <div style={{ 
                                                                backdropFilter: 'blur(50px)',
                                                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                                borderRadius: "8%",
                                                                padding: '8px',
                                                                color: 'white' }}>
                                                                {scorecard.representative_name ? 
                                                                    <>
                                                                    <b>Summary:</b> {scorecard.assessments_summary}<br /><br />
                                                                    <b>Action Item:</b> {scorecard.action_item}<br /><br />
                                                                    {/* <b>Read the full assessment below.</b> */}
                                                                    </>
                                                                    : <>{currentTime}: {serverMessage}</>
                                                                }
                                                            </div>
                                                        </div>
                                                        </>
                                                        : ""
                                                    }

                                                    {1 == 0 ? 
                                                        <>
                                                            <Form.Label htmlFor="rubric"><b>{dialogue.rubric_type} Assessment Checklist:</b></Form.Label>
                                                            <Form.Control 
                                                                as="textarea"
                                                                id="rubric"
                                                                name="rubric"
                                                                value={dialogue.rubric}
                                                                onChange={handleChange}
                                                                placeholder=""
                                                                rows="5"
                                                                className="caret-color-important"
                                                                style={{
                                                                    caretColor: "black",
                                                                    height: "34.5rem",
                                                                    borderColor: "#c3d0e3",
                                                                    padding: "0.375rem 0.75rem",
                                                                    fontSize: "0.91rem",
                                                                    lineHeight: "1.5",
                                                                    color: "#495057",
                                                                    backgroundColor: "#d3d7f5",
                                                                    borderRadius: "0.75rem",
                                                                    boxShadow: "inset 0 0.25rem 0.5rem rgba(0, 0, 0, 0.075)"
                                                                }}></Form.Control>
                                                        </>
                                                        : ""
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>

                                <Row className="g-4" style={{ marginTop: "1px" }}>
                                    <Col md="6">
                                        <div className="d-flex">
                                            <div className="mb-3 flex-grow-1">
                                                {serverMessage && scorecard.representative_name ?
                                                    <>
                                                    <b>Full Assessment of {scorecard.representative_name}'s Call with Customer {scorecard.customer_name}</b><hr />
                                                    <ol>
                                                        {scorecard.assessments.map((assessment, index) => {
                                                            const parts = assessment.split(":");
                                                            const dialogue_quote = scorecard.dialogue_quotes[index];

                                                            let dialogueQuoteElements;
                                                            if (dialogue_quote) {
                                                                // Split the dialogue into lines
                                                                const lines = dialogue_quote.split('\n');
                                                                dialogueQuoteElements = lines.map((line, lineIndex) => {
                                                                    // Find the index of the first colon in each line
                                                                    const firstColonIndex = line.indexOf(':');
                                                                    if (firstColonIndex !== -1) {
                                                                        // Split the line at the first colon
                                                                        const firstPart = line.substring(0, firstColonIndex);
                                                                        const remaining = line.substring(firstColonIndex + 1);
                                                                        return (
                                                                            <React.Fragment key={lineIndex}>
                                                                                <b>{firstPart}</b>{`:${remaining}`}
                                                                                {lineIndex < lines.length - 1 && <br />}
                                                                            </React.Fragment>
                                                                        );
                                                                    } else {
                                                                        // If no colon is present in the line, display the line as is
                                                                        return (
                                                                            <React.Fragment key={lineIndex}>
                                                                                {line}
                                                                                {lineIndex < lines.length - 1 && <br />}
                                                                            </React.Fragment>
                                                                        );
                                                                    }
                                                                });
                                                            }

                                                            return (
                                                                <li key={index}>
                                                                    <b>{parts[0]}</b>{parts[1] && `:${parts[1]}`}<br /><br />
                                                                    {dialogueQuoteElements}<br /><br />
                                                                </li>
                                                            );
                                                        })}

                                                    </ol>
                                                    </>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="d-flex">
                                            <div className="mb-3 flex-grow-1">
                                                {serverMessage ?
                                                    (scorecard.representative_name ?
                                                        <>
                                                        <b>Enhanced Dialogue with Prospect:</b><hr />
                                                        {/* {scorecard.enhanced_dialogue.map((talk, index) => (
                                                            <div key={index}>
                                                                {talk}
                                                            </div>
                                                        ))} */}

                                                        {scorecard.enhanced_dialogue.map((talk, index) => {
                                                            const parts = talk.split(":");
                                                            return (
                                                                <div key={index}>
                                                                    <b>{parts[0]}</b>{parts.length > 1 && `:${parts[1]}`}
                                                                </div>
                                                            );
                                                        })}
                                                        </>
                                                        : "")
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Footer />
            </div>
        </React.Fragment>
    );
}
