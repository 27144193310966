import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from "react-bootstrap";
import { useTable, useSortBy, useFilters } from 'react-table';
import Modal from 'react-modal';
import Config from '../Config.json';

const columns = [
    {
        Header: 'Name',
        accessor: 'Full_Name', 
    },
    {
        Header: 'Account',
        accessor: 'Account_Name.name', 
    },
    {
        Header: 'Email',
        accessor: 'Email', 
    },
    {
        Header: 'Phone',
        accessor: 'Phone', 
    },
];

const appRoot = document.getElementById('root');

Modal.setAppElement(appRoot); 

const Table = ({ contacts, updateContacts, serverMessage }) => {
    const [data, setData] = useState(contacts);
    const [contactNotes, setContactNotes] = useState([]);
    const [localServerMessage, setLocalServerMessage] = useState(serverMessage);
    const [aiRecommendations, setAIRecommendations] = useState({
        'primary_recommendation': {'recommended_action': '', 'recommended_message': ''},
        'secondary_recommendation': {'recommended_action': '', 'recommended_message': ''}
    });
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [submitButtonText, setSubmitButtonText] = useState('Get AI Assistance for This Lead');
    const [submitDisabled, setSubmitDisabled] = useState(false);
    
    const defaultColumn = React.useMemo(
        () => ({
            Filter: '', // Add a default filter with an empty string value (Modify as needed for custom filtering)
        }),
        []
    );
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useSortBy
    );

    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const styles = {
        table: {
            borderCollapse: 'collapse', // Ensure borders don't overlap
        },
        th: {
            border: '1px solid black', 
            padding: '8px',   
        },
        td: {
            border: '1px solid black', 
            padding: '8px',
        },
    };

    const fetchContactNotes = (event) => {
        fetch('https://xyx68xpssf.execute-api.us-east-2.amazonaws.com/masterclosercontactnotes', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.masterclosercontactnotes,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'master_closer_id': getMasterCloserId(), 'contact_id': selectedRowData.id})
        })
            .then((response) => response.json())
            .then((response) => {
                const apiResponse = response.length > 0 ? response : ['No notes yet for this contact'];
                setContactNotes(apiResponse);

                const updatedData = data.map((contact) => {
                    if (contact.id === selectedRowData.id) {
                        return { ...contact, Notes: apiResponse };
                    }
                    return contact;
                });
                setData(updatedData);
                updateContacts(updatedData);
                
                // setSubmitButtonText('Get AI Assistance for This Lead')
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const getAIAssistance = (event) => {
        setSubmitButtonText('Master Closer AI Activated')
        setSubmitDisabled(true);

        fetch('https://koje92w2u7.execute-api.us-east-2.amazonaws.com/mastercloserassistancerequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.mastercloserassistancerequest,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'master_closer_id': getMasterCloserId(),
                'contact_id': selectedRowData.id,
                'full_name': selectedRowData.Full_Name,
                'title': selectedRowData.Title,
                'account': selectedRowData.Account_Name.name
            })
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
            })
            .catch(function (err) {
                console.log('Error:', err);
                setSubmitButtonText('Error Occurred. Try Again');
                setSubmitDisabled(false);
            });
    }

    const handleCellClick = (row) => {
        setContactNotes(row.original.Notes ? row.original.Notes : []);
        setSelectedRowData(row.original); // Capture the clicked row's data
        setShowModal(true);
        setSubmitButtonText('Get AI Assistance for This Lead')
        setSubmitDisabled(false);
    };
    
    const handleCloseModal = () => {
        setContactNotes([]);
        setLocalServerMessage('');
        setAIRecommendations(null)
        setSelectedRowData(null); // Clear the selection on close
        setShowModal(false);
    };

    const getMasterCloserId = () => {
        return `${localStorage.getItem('user_id')}-${localStorage.getItem('optimus')}-text`;
    }

    useEffect(() => {
        if (selectedRowData) {
            fetchContactNotes();
        }
    }, [selectedRowData]);

    useEffect(() => {
        setLocalServerMessage(serverMessage);
        setAIRecommendations(serverMessage && String(serverMessage).includes('{') ? JSON.parse(serverMessage) : null)
    }, [serverMessage]);

    return (
        <>
        <table {...getTableProps()} style={styles.table}>
            <thead>
                {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ ...styles.th, cursor: 'pointer' }}>
                        {column.render('Header')}
                        <span>
                            {/* Add sort indicator icon if column is sortable */}
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </span>
                        {/* Include column's filter component here if needed */}
                    </th>
                    ))}
                </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                    <tr {...row.getRowProps()}
                        onMouseEnter={() => setHoveredRowIndex(index)}
                        onMouseLeave={() => setHoveredRowIndex(null)}
                        style={{
                            ...styles.tr,
                            backgroundColor: hoveredRowIndex === index ? '#f2f2f2' : 'transparent', // Change color on hover
                            cursor: 'pointer'
                        }}
                    
                    >
                        {row.cells.map((cell) => {
                        return (
                            <td 
                                {...cell.getCellProps()} 
                                style={styles.td}
                                onClick={(event) => handleCellClick(row, event)} // Add event parameter if needed
                                // key={cell.row.index}
                                >
                                {cell.render('Cell')}
                            </td>
                        );
                        })}
                    </tr>
                    );
                })}
            </tbody>
        </table>

        <Modal
            isOpen={showModal}
            onRequestClose={handleCloseModal}
            appElement={document.getElementById('root')}
            style={{
                content: {
                  backgroundColor: '#b9d9fa',
                  width: '72%',
                  height: '95%',
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  overflow: 'auto',
                  zIndex: 10000 // Increase zIndex value
                },
                overlay: {
                  zIndex: 9999, // Ensure the overlay also has a high zIndex
                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the alpha value for opacity, 0.5 in this case
                }
              }}
            >
                <button 
                    onClick={handleCloseModal} 
                    style={{
                    position: 'absolute',
                    top: '10px',
                    right: '30px',
                    background: 'transparent',
                    border: 'none',
                    fontSize: '2.5em', // Adjust size as needed
                    cursor: 'pointer',
                    }}
                >
                    &times; {/* This is a common symbol used for close buttons */}
                </button>

                {
                    selectedRowData ? 

                    <>
                    <h2>{selectedRowData.Full_Name}</h2><br />
                    
                    <table>
                        <tbody>
                            <tr>
                                <th>Title:</th>
                                <td>{selectedRowData.Title}</td> 
                            </tr>
                            <tr>
                                <th style={{ paddingRight: '20px' }}>Account:</th>
                                <td>{selectedRowData.Account_Name.name}</td> 
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>{selectedRowData.Email}</td>
                            </tr>
                            <tr>
                                <th>Phone:</th>
                                <td>{selectedRowData.Phone}</td>
                            </tr>
                            <tr>
                                <th>ID:</th>
                                <td>{selectedRowData.id}</td>
                            </tr>
                        </tbody>
                    </table>

                    <br />

                    <div><b>Notes:</b> {}</div>
                    <div>
                        <ul>
                            {
                                contactNotes.length > 0 ?
                            
                                    contactNotes.map((note, index) => 
                                        <li key={index}>{note}</li>
                                    )
                                    : 'Loading notes from CRM...'
                            }
                        </ul>
                    </div>

                    <div>
                        <button
                            onClick={getAIAssistance}
                            className="btn btn-primary"
                            style={{ width: "250px" }}
                            disabled={submitDisabled}>
                                {submitButtonText}
                        </button>
                    </div>
                    <div>
                        <br />
                        {aiRecommendations ?
                            <>
                            {aiRecommendations.primary_recommendation && (
                                <>
                                <div><b>AI Agent One Recommended Next Step:</b> {aiRecommendations.primary_recommendation.recommended_action}</div><br />
                                
                                <div><b>AI Agent One Recommended Email:</b> {aiRecommendations.primary_recommendation.recommended_message}</div>
                                </>
                            )}
                            <hr />

                            {aiRecommendations.secondary_recommendation && (
                                <>
                                <div><b>AI Agent Two Recommended Next Step:</b> {aiRecommendations.secondary_recommendation.recommended_action}</div><br />
                                
                                <div><b>AI Agent Two Recommended Email:</b> {aiRecommendations.secondary_recommendation.recommended_message}</div>
                                </>
                            )}
                            </>
                            : (localServerMessage ? localServerMessage : '')
                        }
                    </div>
                    </>

                    : ''
                }
        </Modal>
        </>
    );
}

export default Table;
