import React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Nav, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Config from '../Config.json';

export default function NotFound() {
    const { prospectId } = useParams();
    const [contactPageURL] = useState('https://galeforce.ai/contact');
    const [imageDirectoryURL] = useState('https://galeforceai-public.s3.us-east-2.amazonaws.com/sectors');
    const [prospectPersonalInfo, setProspectPersonalInfo] = useState({
        'request_id': '',
        'prospect_personal_data': '',
        'prospect_email': ''
    });
    const [prospect, setProspect] = useState({
        'prospect_id': '',
        'prospect_name': '',
        'prospect_offering': '',
        'prospect_image': '',
        'prospect_segue': '',
        'provider_name': '',
        'provider_website_url': '',
        'provider_offering_details': [{'title': '', 'text': '', 'image': ''}],
        'sector': ''
    });
    const [showProgress, setShowProgress] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const pageVersion = window.location.href.includes('version-2.0') ? 2.0 : 1.0;

    const fetchProspectData = () => {
        fetch('https://cy4ck3q87l.execute-api.us-east-2.amazonaws.com/landingpageprospectdetails', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.landingpageprospectdetails,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'prospect_id': prospectId
            })
        })
            .then((response) => response.json())
            .then((response) => {
                setProspect(response);
                console.log(response);
                document.title = response.prospect_name;
                
                setProspectPersonalInfo({
                    request_id: response.prospect_id,
                    prospect_personal_data: '',
                    prospect_email: ''
                });

                setShowProgress(false);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProspectPersonalInfo({
            ...prospectPersonalInfo,
            [name]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();  // Prevent the default form submit action.
        console.log(prospectPersonalInfo);
        setSubmitDisabled(true);
        
        fetch('https://5e6bv382i7.execute-api.us-east-2.amazonaws.com/landingpageprospectrequestupsert', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.landingpageprospectrequestupsert,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(prospectPersonalInfo)
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                setShowProgress(false);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    }

    // document.body.classList.remove("sidebar-show");

    useEffect(() => {
        document.title = 'Welcome';
        fetchProspectData();
    }, [])

    

    return (
        <div className="page-error">
            <div className="header">
                <Container>
                    <Nav.Link href={prospect.provider_website_url ? prospect.provider_website_url : 'https://galeforce.ai/'}
                              className="header-logo"
                              target="_blank">
                                {prospect.provider_name ? prospect.provider_name + ' and ' + prospect.prospect_name : "AI: Your business' lifeline to the future"}
                    </Nav.Link>
                    {/* <Nav className="nav-icon">
                        <Nav.Link href=""><i className="ri-twitter-fill"></i></Nav.Link>
                        <Nav.Link href=""><i className="ri-github-fill"></i></Nav.Link>
                        <Nav.Link href=""><i className="ri-dribbble-line"></i></Nav.Link>
                    </Nav> */}
                </Container>
            </div>

            <div className="content">
                <Container style={{
                        backgroundImage: `url(${imageDirectoryURL + (prospect.sector ? '/' + prospect.sector.replace(' & ', '_and_') : '') + '/' + (prospect.prospect_image ? prospect.prospect_image : 'xxx.png')})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: "1%",
                        padding: '50px',
                        width: '100vw',
                        height: '90vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative'  // Add relative positioning to allow placing the arrow icon at the bottom
                }}>
                    
                    <Row className="gx-5" style={{ color: 'white' }}>
                        <Col xs="12" md="10" lg="12" className="d-flex flex-column align-items-center">
                            {/* <h2><b>{prospect.prospect_name}</b></h2> */}
                            <div style={{
                                backdropFilter: 'blur(50px)',
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: "8%",
                                padding: '20px',
                                color: 'white'
                            }}>
                                <p className="text-left" style={{ color: prospect.sector ? 'inherit' : 'black', fontSize: prospect.sector ? 'inherit' : '30px' }}>
                                    {prospect.prospect_offering}
                                </p>

                                {prospect.prospect_segue}
                            </div>
                        </Col>
                    </Row>

                    {/* Scroll indicator */}
                    <div style={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        animation: 'bounce 1s infinite',
                        // cursor: 'pointer'
                    }}>
                        <i className="fas fa-chevron-down" style={{fontSize: '2rem', color: 'white'}}></i>
                    </div>
                </Container>
                
                {prospect.provider_name && pageVersion === 1.0 && false ?
                    <Container style={{
                            backgroundImage: `url('https://galeforceai-public.s3.us-east-2.amazonaws.com/robot-on-laptop.jpg')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            borderRadius: "1%",
                            padding: '50px',
                            width: '100vw',
                            height: '90vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative'  // Add relative positioning to allow placing the arrow icon at the bottom
                    }}>
                        
                        <Row className="gx-5" style={{ color: 'white' }}>
                            <Col xs="12" md="10" lg="12" className="d-flex flex-column align-items-center">
                                <div style={{
                                    backdropFilter: 'blur(50px)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    borderRadius: "8%",
                                    padding: '20px',
                                    color: 'white'
                                }}>
                                    Before you dive into the content we've carefully prepared for you below, a quick note from us at Gale Force AI: personalization is our forte, and your unique interests are the final touch. While your personalized message is already great, imagine how much more tailored it can be with just a bit more information from you. Could you share a passion or hobby that resonates with you? For example, do you love baseball? Are you an avid chess player? Share your passions, and let our AI system work its magic. Expect a result that reflects your individuality, artfully crafted by the AI, promising a unique, captivating experience. We'll email you once it's ready.
                                </div><br />

                                {prospect.provider_name ?
                                    <Form onSubmit={handleSubmit}>
                                        <Row className="align-items-center">
                                            <Col xs={12} sm={6} md={6} lg={5}>
                                                <Form.Control type="text" id="prospect_personal_data" name="prospect_personal_data" value={prospectPersonalInfo.prospect_personal_data} onChange={handleChange} placeholder="i.e. I love to play chess." style={{ backgroundColor: '#4f609c', color: 'white', caretColor: 'white' }} />
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={5}>
                                                <Form.Control type="text" id="prospect_email" name="prospect_email" value={prospectPersonalInfo.prospect_email} onChange={handleChange} placeholder="Email" style={{ backgroundColor: '#4f609c', color: 'white', caretColor: 'white' }} />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={2}>
                                                <Button type="submit" className="mb-2">Submit</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                : ''}
                            </Col>
                        </Row>

                        {/* Scroll indicator */}
                        <div style={{
                            position: 'absolute',
                            bottom: '10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            animation: 'bounce 1s infinite',
                            // cursor: 'pointer'
                        }}>
                            <i className="fas fa-chevron-down" style={{fontSize: '2rem', color: 'white'}}></i>
                        </div>
                    </Container>
                    : ''
                }

                {prospect.provider_name && pageVersion === 1.0 ?
                    <Container>
                        <Row className="gx-5">
                            <Col xs="12" md="5" lg="6" className={'d-flex flex-column align-items-center col-lg-6 col-md-5 col-12'}>
                                <>
                                <h5><b>Let's personalize this page even more just for you</b></h5><br />
                                <p className="text-left" style={{ fontSize: 'larger' }}>
                                    Before you dive into the content we've carefully prepared for you below, a quick note from us at Gale Force AI: <b>personalization</b> is our forte, and your unique interests are the final touch. While your personalized message is already great, imagine how much more tailored it can be with just a bit more information from you. Could you <b>share a passion or hobby</b> that resonates with you? For example, do you love baseball? Are you an avid chess player? Share your passions, and let our AI system work its magic. Expect a result that reflects your individuality, artfully crafted by the AI, promising a <b>unique, captivating experience</b>. We'll email you once it's ready.
                                </p>
                                <Form onSubmit={handleSubmit}>
                                    <Row className="align-items-center">
                                        <Form.Control type="text" id="prospect_personal_data" name="prospect_personal_data" value={prospectPersonalInfo.prospect_personal_data} onChange={handleChange} placeholder="I love to play chess. My favorite band is Queen." className="mb-2" disabled={submitDisabled} required />
                                        <Form.Control type="email" id="prospect_email" name="prospect_email" value={prospectPersonalInfo.prospect_email} onChange={handleChange} placeholder="Email" className="mb-2" title="Please enter a valid email address." disabled={submitDisabled} required />
                                        <Button type="submit" disabled={submitDisabled} style={{ width: '200px' }}>{submitDisabled ? 'Got it. Thanks so much.' : 'Submit'}</Button>
                                    </Row>
                                </Form>
                                </>
                            </Col>
                            <Col className={'mb-5 mb-lg-0 order-1 order-md-0'}>
                                <img
                                    src={'https://galeforceai-public.s3.us-east-2.amazonaws.com/robot-on-laptop.jpg'}
                                    style={{
                                        borderRadius: '15%', // More pronounced rounded corners
                                        boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.3)', // Stronger drop shadow for more depth
                                        transition: 'all 0.3s ease-in-out', // Smooth transition for all properties
                                        margin: '10px', // Add some space around the image
                                        border: '2px solid #ddd', // Thicker border for more definition
                                        overflow: 'hidden', // Ensure no content spills outside the border-radius
                                        objectFit: 'cover', // Image covers the area, maintaining aspect ratio
                                        height: 'auto', // Maintain aspect ratio
                                        maxWidth: '100%', // Ensure image is responsive
                                        cursor: 'pointer' // Change cursor on hover to indicate interactivity
                                    }}
                                    onMouseOver={(e) => {
                                        e.currentTarget.style.filter = 'brightness(1.2) saturate(1.5)';
                                        e.currentTarget.style.transform = 'rotate(-0deg) scale(1.1)';
                                    }}
                                    onMouseOut={(e) => {
                                        e.currentTarget.style.filter = 'brightness(1.1) saturate(1)';
                                        e.currentTarget.style.transform = 'rotate(0deg) scale(1)';
                                    }}
                                    className="img-fluid"
                                    alt={'Personalize'}
                                />
                            </Col>
                        </Row>
                    </Container>
                    : ''
                }

                {
                    prospect && prospect.provider_offering_details ?
                    prospect.provider_offering_details.map((offering, index) => {
                        return offering.title ? (
                            <Container key={index}>
                                <Row className="gx-5">
                                    {/* Display image on the left for even indices and on the right for odd indices */}
                                    <Col xs="12" md="5" lg="6" className={`${index % 2 === 0 ? 'mb-5 mb-lg-0 order-1 order-md-0' : ''} ${index % 2 !== 0 ? 'd-flex flex-column align-items-center' : ''}`}>
                                        {index % 2 === 0 ? (
                                            <img
                                                src={imageDirectoryURL + '/' + (prospect.sector ? prospect.sector.replace(' & ', '_and_') : '') + '/' + offering.image}
                                                style={{
                                                    borderRadius: '15%', // More pronounced rounded corners
                                                    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.3)', // Stronger drop shadow for more depth
                                                    transition: 'all 0.3s ease-in-out', // Smooth transition for all properties
                                                    margin: '10px', // Add some space around the image
                                                    border: '2px solid #ddd', // Thicker border for more definition
                                                    overflow: 'hidden', // Ensure no content spills outside the border-radius
                                                    objectFit: 'cover', // Image covers the area, maintaining aspect ratio
                                                    height: 'auto', // Maintain aspect ratio
                                                    maxWidth: '100%', // Ensure image is responsive
                                                    cursor: 'pointer' // Change cursor on hover to indicate interactivity
                                                }}
                                                onMouseOver={(e) => {
                                                    e.currentTarget.style.filter = 'brightness(1.2) saturate(1.5)';
                                                    e.currentTarget.style.transform = 'rotate(-0deg) scale(1.1)';
                                                }}
                                                onMouseOut={(e) => {
                                                    e.currentTarget.style.filter = 'brightness(1.1) saturate(1)';
                                                    e.currentTarget.style.transform = 'rotate(0deg) scale(1)';
                                                }}
                                                className="img-fluid"
                                                alt={offering.title}
                                            />
                                        ) : (
                                            <>
                                            <h5><b>{offering.title}</b></h5><br />
                                            <p className="text-left" style={{ fontSize: 'larger' }}>{offering.text}</p><br />
                                            <Nav.Link href={contactPageURL} className="btn btn-primary btn-error" style={{ color: 'white' }}>
                                                Contact Us
                                            </Nav.Link>
                                            </>
                                        )}
                                    </Col>

                                    {/* Display content on the right for even indices and on the left for odd indices */}
                                    <Col xs="12" md="5" lg="6" className={`${index % 2 !== 0 ? 'mb-5 mb-lg-0' : ''} ${index % 2 === 0 ? 'd-flex flex-column align-items-center order-0 order-md-1' : ''}`}>
                                        {index % 2 !== 0 ? (
                                            <img
                                                src={imageDirectoryURL + '/' + (prospect.sector ? prospect.sector.replace(' & ', '_and_') : '') + '/' + offering.image}

                                                style={{
                                                    borderRadius: '15%', // More pronounced rounded corners
                                                    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.3)', // Stronger drop shadow for more depth
                                                    transition: 'all 0.3s ease-in-out', // Smooth transition for all properties
                                                    margin: '10px', // Add some space around the image
                                                    border: '2px solid #ddd', // Thicker border for more definition
                                                    overflow: 'hidden', // Ensure no content spills outside the border-radius
                                                    objectFit: 'cover', // Image covers the area, maintaining aspect ratio
                                                    height: 'auto', // Maintain aspect ratio
                                                    maxWidth: '100%', // Ensure image is responsive
                                                    cursor: 'pointer' // Change cursor on hover to indicate interactivity
                                                }}
                                                onMouseOver={(e) => {
                                                    e.currentTarget.style.filter = 'brightness(1.2) saturate(1.5)';
                                                    e.currentTarget.style.transform = 'rotate(-0deg) scale(1.1)';
                                                }}
                                                onMouseOut={(e) => {
                                                    e.currentTarget.style.filter = 'brightness(1.1) saturate(1)';
                                                    e.currentTarget.style.transform = 'rotate(0deg) scale(1)';
                                                }}            
                                                className="img-fluid"
                                                alt={offering.title}
                                            />
                                        ) : (
                                            <>
                                            <h5><b>{offering.title}</b></h5><br />
                                            <p className="text-left" style={{ fontSize: 'larger' }}>{offering.text}</p><br />
                                            <Nav.Link href={contactPageURL} className="btn btn-primary btn-error" style={{ color: 'white' }}>
                                                Contact Us
                                            </Nav.Link>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                        ) : null;
                    }) : null
                }
            </div>
        </div>
  )
}