import React from "react";
// import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>Copyright &copy; 2024 Gale Force AI LLC. All Rights Reserved.</span>
      <span>Created by <a href="http://galeforce.ai" target="_blank" rel="noopener noreferrer">Gale Force AI</a></span>
    </div>
  )
}